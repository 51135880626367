<template id="task-template">
    <!-- Content wrapper -->

    <!-- Content -->

    <div class="container-xxl flex-grow-1 container-p-y">


        <h4 class="fw-bold py-3 mb-4">
            <router-link :to="'/project/' + $route.params.project_id + '/task'" class="text-muted fw-light">Wróć do listy
                zadań
            </router-link>
        </h4>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 @dblclick="editTitle = true" v-show="!editTitle">{{ item.task_name }}</h4>
                        <input type="text" class="form-control" @blur="editTitle = false" v-if="editTitle"
                            v-model="item.task_name" @change="editTitle = false; submitForm()">
                        <div @dblclick="editDescription = true" v-show="!editDescription">
                            {{ item.task_description ? '' : 'Brak opisu' }}

                            <div v-html="item.task_description">

                            </div>
                        </div>
                        <div v-show="editDescription">
                            <editor :init="tinymce" v-model="item.task_description"></editor>
                            <button @click="editDescription = false; submitForm()"
                                class="btn btn-outline-primary mt-4">Zapisz</button>
                        </div>
                    </div>
                    <div class="col-md-3 border-start">
                        <div class="mb-4">
                            <label>Status</label>
                            <div v-if="!editStatus" class="editable-hover" @click="editStatus = true">
                                <div class="d-flex align-items-center gap-2">

                                    <div class="h3"
                                        :class="'btn w-100 btn-' + task_statuses.find(it => it.value == item.task_status)?.color">
                                        {{ task_statuses.find(it => it.value == item.task_status)?.name }}
                                    </div>
                                </div>

                            </div>
                            <select @change="editStatus = false; submitForm()" v-if="editStatus" class="form-select"
                                v-model="item.task_status" :class="{ 'is-invalid': validation.user_id != '' }">
                                <option value=""></option>
                                <option v-for="i, index in task_statuses" :value="i.value" :key="index">{{
                                    i.name ? i.name : i.username }}</option>
                            </select>
                        </div>

                        <div class="mb-4">
                            <label>Deadline</label>
                            <div v-if="!editDate" class="editable-hover" @dblclick="editDate = true">
                                <div class="d-flex align-items-center gap-2">

                                    <div class="h3">{{ item.task_end_at.substr(0,16) }}
                                    </div>
                                </div>

                            </div>
                            <datepicker @close="editDate = false; submitForm()" v-if="editDate" format="YYYY-MM-DD HH:mm" type="datetime" value-type="YYYY-MM-DD HH:mm:ss" class="w-100"
                                v-model="item.task_end_at" :class="{ 'is-invalid': validation.user_id != '' }">
      
                            </datepicker>
                        </div>
                        <div class="mb-4">
                            <label>Osoba przypisana</label>
                            <div class="editable-hover" @dblclick="showEditField('editUser', 'userSelect')"
                                v-show="!editUser">
                                <div class="d-flex align-items-center gap-2">

                                    <div class="avatar avatar-sm">

                                        <img class="me-2" :src="getUserImage(item.user_id)">
                                    </div>
                                    <span class="me-auto">{{ getUser(item.user_id).name }}</span>
                                </div>

                            </div>
                            <select @change="editUser = false; submitForm()" v-if="editUser" class="form-select"
                                v-model="item.user_id" :class="{ 'is-invalid': validation.user_id != '' }">
                                <option value=""></option>
                                <option v-for="i, index in slownik_user" :value="i.user_id" :key="index">{{
                                    i.name ? i.name : i.username }}</option>
                            </select>
                        </div>
                        <div class="mb-4">
                            <label>Priorytet</label>
                            <div class="editable-hover ps-11" @dblclick="showEditField('editPriority', 'prioritySelect')"
                                v-show="!editPriority">
                                <h3>{{ priority[item.task_priority] ? priority[item.task_priority] : '---' }}</h3>
                            </div>
                            <div v-if="editPriority">
                                <select ref="prioritySelect" class="form-select" @blur="editPriority = false"
                                    @change="editPriority = false, submitForm()" v-model="item.task_priority"
                                    :class="{ 'is-invalid': validation.user_id != '' }">
                                    <option value="low">
                                        Niski
                                    </option>
                                    <option value="normal">
                                        Normalny
                                    </option>
                                    <option value="hight">
                                        Wysoki
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label>Osoba zgłaszająca</label>
                            <div class="d-flex align-items-center gap-2">
                                <div class="avatar avatar-sm">

                                    <img class="me-2" :src="getUserImage(item.task_created_user)">
                                </div>
                                <span class="me-auto">{{ getUser(item.task_created_user).name }}</span>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <small>Utworzono: {{ item.task_created_at }}</small>
                        </div>
                        <div>
                            <small>Zaktualizowano: {{ item.task_updated_at }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- /Create Deal Wizard -->



    </div>
    <!-- / Content -->
</template>
<script>
import tinymceConfig from '../../components/tinimce/config.js'
import Editor from '@tinymce/tinymce-vue'
import axios from '../../api/axios';
// import api from '../../api/api';
import datepicker from 'vue2-datepicker'
import dayjs from 'dayjs';
export default {
    name: 'task_show',
    template: '#show-template',
    components: {
        'editor': Editor,
        datepicker
    },
    computed: {
        slownik_user: function () { return this.$store.state.slownik_user }
    },
    data: function () {
        return {
            tinymce: tinymceConfig,
            isLoading: true,
            // vtinymce: vtinymce,
            id: 0,
            isEdit: false,
            item: {
                task_name: '',
                task_status: '',
                task_description: '',
                task_priority: '',
                user_id: '',
                task_module: '',
                task_post_id: 0,
                task_end_at: '',
                notifications: [],
            },
            header: {
                task_name: '',
            },
            editUser: false,
            editPriority: false,
            editTitle: false,
            editDescription: false,
            editDate: false,
            editStatus: false,
            validation: {
                task_name: '',
                task_description: '',
                task_status: '',
                task_priority: '',
                user_id: '',
                task_end_at: '',
            },
            priority: { 'low': 'Niski', 'medium': 'Średni', 'hight': 'Wysoki' },
            task_statuses: [{
                name: 'W toku',
                value: 'progress',
                color: 'outline-warning'
            }, {
                name: 'Do zrobienia',
                value: 'todo',
                color: 'outline-primary'
            }, {
                name: 'Do sprawdzenia',
                value: 'check',
                color: 'outline-info'
            }, {
                name: 'Gotowe',
                value: 'done',
                color: 'outline-success'
            },
            ]
        }
    },
    methods: {
        onDelete() {
            this.$router.back();
        },
        dateF: function (date) {
            if (dayjs().format('YYYY') == dayjs(date).format('YYYY')) {
                return dayjs(date).format('D MMMM');
            }
            return dayjs(date).format('D MMMM YYYY');
        },
        showEditField(name, ref) {
            this[name] = true;
            let vm = this;
            setTimeout(function () {
                vm.$refs[ref].focus();
            }, 100);
        },
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar;
        },

        getUser(id) {
            var name = { name: 'nie przypisano' };
            this.slownik_user.forEach(function (item) {
                if (item.id == id) {
                    name = item;
                    name.name = name.username
                }
            })
            return name;
        },
        addNotification: function () {
            this.item.notifications.push({
                task_notification_id: '',
                task_notification_number: '',
                task_notification_type: '',
                task_notification_status: '',
                task_id: 0,
                task_notification_end_at: '',
                task_notification_created_at: '',
                task_notification_updated_at: '',
                task_notification_deleted_at: '',
            })
        },
        getStatus: function (status) {
            var d = {}
            this.task_statuses.forEach(item => {
                if (status == item.value) {
                    d = item;
                }
            })
            return d;
        },
        deleteNotification: function (index) {
            this.item.notifications.splice(index, 1);
            this.submitForm()
        },
        hide: function () {
            this['editUser'] = false
        },
        cancelForm: function () {
            this.$router.push('/');
        },
        back: function () {
            this.$router.back();
        },
        submitForm: function () {
            for (const property in this.validation) {
                this.validation[property] = '';
            }
            for (const tablica in this.representative_validation) {
                for (const property in this.representative_validation[tablica]) {
                    this.representative_validation[tablica][property] = '';
                }
            }
            if (this.isEdit > 0) {
                this.updatePost();
            } else {
                this.createPost();
            }
            console.log(this.item);
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get('api/v1/task/' + this.id).then(response => {
                //stworzenie pustych walidacji
                console.log(response.data.representative);
                vm.item = response.data;
                vm.header.task_name = vm.item.task_name;
                vm.isLoading = false;
            });
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        // createPost: function () {
        //     return;
        //     var vm = this;
        //     axios.post('api/v1/task', this.item).then(response => {
        //         this.$router.push('/');
        //     }).catch(error => {
        //         vm.handleAxiosError(error);
        //     });
        // },
        updatePost: function () {
            axios.put('api/v1/task/' + this.id, this.item).then(() => {
                this.$toast('Zaktualizowano zadanie!')
            }).catch(this.handleAxiosError);
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.isEdit = (this.id > 0) ? 1 : 0;
        if (this.isEdit) {
            this.getPost()
        }
    }
}
</script>
<style>
.kanban-board-header .kanban-title-board {
    font-size: 1.125rem;
    max-width: 13rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
}

.kanban-title-board::first-letter {
    text-transform: capitalize;
}
</style>