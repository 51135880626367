export default {
    autoresize_max_height: window.innerHeight - window.innerHeight / 4,
    width: '100%',
    language: "pl",
    language_url: 'https://www.magico.pl/assets/js/tinymce/langs/pl.js',
    forced_root_block: "p",
    entity_encoding: 'named',
    entities: '160,nbsp,38,amp,60,lt,62,gt',
    valid_elements: '*[*]',
    document_base_url:  window.location.toString() ,
    relative_urls: false,
    browser_spellcheck: true,
    autoresize_bottom_margin: 10,
    autoresize_min_height: 90,
    image_caption: true,
    image_description: true,
    image_title: true,
    visualblocks_default_state: true,
    visualchars_default_state: false,
    formats: {
        mainparagraph: {
            inline: 'span',
            styles: {
                fontSize: '18px'
            },
            classes: 'main_paragraph'
        },
        firstlink: {
            inline: 'span',
            styles: {
                fontSize: '16px'
            },
            classes: 'default_button_span black'
        },
        secondlink: {
            inline: 'span',
            styles: {
                fontSize: '16px'
            },
            classes: 'default_button_span'
        }
    },
    style_formats: [{
        title: 'Headers',
        items: [{
            title: 'h1',
            block: 'h1'
        }, {
            title: 'h2',
            block: 'h2'
        }, {
            title: 'h3',
            block: 'h3'
        }, {
            title: 'h4',
            block: 'h4'
        }, {
            title: 'h5',
            block: 'h5'
        }, {
            title: 'h6',
            block: 'h6'
        }]
    }, {
        title: 'Blocks',
        items: [{
            title: 'Główny akapit',
            format: 'mainparagraph'
        }, {
            title: 'LinkURL - 1',
            format: 'firstlink'
        }, {
            title: 'LinkURL - 2',
            format: 'secondlink'
        }, {
            title: 'p',
            block: 'p'
        }, {
            title: 'div',
            block: 'div'
        }, {
            title: 'pre',
            block: 'pre'
        }]
    }, {
        title: 'Containers',
        items: [{
            title: 'section',
            block: 'section',
            wrapper: true,
            merge_siblings: false
        },
        //{ title: 'article', block: 'article', wrapper: true, merge_siblings: false },
        {
            title: 'blockquote',
            block: 'blockquote',
            wrapper: true
        },
            //{ title: 'hgroup', block: 'hgroup', wrapper: true },
            //{ title: 'aside', block: 'aside', wrapper: true },
            //{ title: 'figure', block: 'figure', wrapper: true }
        ]
    }],
    // paste_word_valid_elements: "table,tr,td,th,b,strong,i,em,h1,h2, strong",
    valid_styles: {
        '*': 'display, margin, float, margin-left, margin-right, margin-top, margin-bottom, padding-left, padding-right, padding-top, padding-bottom, text-decoration, border,font-size,color,background,background-color,line-height,text-align,list-style-type, border-radius',
    },
    plugins: [],
    removed_menuitems: 'newdocument',
    toolbarxx: "undo redo | bold italic | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | link image forecolor backcolor",
    toolbar1: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | forecolor backcolor',
    toolbar2: '',
    menubar: true,
    // setup: function (editor) {
    //     // editor.on('change', function () {
    //     //     editor.save();
    //     //     editor.getElement().dispatchEvent(new Event('input'));
    //     // });
    // }

}